import { Button, FormControl } from '@mui/material';
import { useState } from 'react';
import {
  Confirm,
  Labeled,
  SelectInput,
  useNotify,
  useTranslate
} from 'react-admin';
import { downloadCertificate } from '../../../core/services/certificate-api.service';
import Modal from '../../shared/Modal';
import { LANGS } from '../../../providers/i18nProvider';
import { FormProvider, useForm } from 'react-hook-form';
import { useAtomValue } from 'jotai';
import { editAtom } from '../../shared/EditListener';

type DownloadCertificateOverlayProps = {
  certificateId: string;
  onDownload: () => void;
};

export default function DownloadCertificateModal({
  certificateId,
  onDownload
}: DownloadCertificateOverlayProps) {
  const t = useTranslate();
  const methods = useForm({ defaultValues: { language: 'es' } });
  const [showModal, setShowModal] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const notify = useNotify();
  async function onSubmit({ language }) {
    try {
      const { data, headers } = await downloadCertificate(
        certificateId,
        language
      );

      const url = URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = url;
      link.download = headers['content-disposition'].slice(
        'attachment; filename="'.length,
        -1
      );
      link.style.display = 'none';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(url);

      onDownload();
    } catch (e) {
      const response = JSON.parse(await e.response.data.text());
      const code = response.errors?.global?.code;
      if (code !== undefined) {
        const message =
          code === 'MISSING_SIGNATURE'
            ? 'resources.certificates.download.errors.missingSignature'
            : code === 'INVALID_SIGNATURE_PASSWORD'
            ? 'resources.certificates.download.errors.invalidSignaturePassword'
            : 'resources.certificates.download.errors.failedToDownload';

        notify(message, { type: 'error' });
      }
    }
  }

  const edited = useAtomValue(editAtom);
  function handleClick() {
    if (edited) {
      setShowConfirmDialog(true);
    } else {
      setShowModal(true);
    }
  }

  function handleConfirm() {
    setShowConfirmDialog(false);
    setShowModal(true);
  }

  function handleCloseConfirmDialog() {
    setShowConfirmDialog(false);
  }

  function handleCloseModal() {
    setShowModal(false);
  }

  return (
    <>
      <Button onClick={handleClick} variant="contained">
        {t('general.download')}
      </Button>
      <Confirm
        isOpen={showConfirmDialog}
        onConfirm={handleConfirm}
        onClose={handleCloseConfirmDialog}
        title="resources.certificates.download.confirm.title"
        content="resources.certificates.download.confirm.content"
      />
      <Modal show={showModal} onClose={handleCloseModal}>
        <Modal.Header
          title={t('resources.certificates.modal.title')}
          onClose={handleCloseModal}
        />
        <Modal.Body>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <FormControl>
                <Labeled className="labeled-input">
                  <SelectInput
                    label={t('resources.certificates.modal.language')}
                    source="language"
                    choices={LANGS.map(({ code, label }) => ({
                      id: code,
                      name: label
                    }))}
                    sx={{ marginTop: '20px' }}
                  ></SelectInput>
                </Labeled>
                <Button variant="contained" type="submit">
                  {t('general.download')}
                </Button>
              </FormControl>
            </form>
          </FormProvider>
        </Modal.Body>
      </Modal>
    </>
  );
}
