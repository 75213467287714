import { DateTime } from 'luxon';
import {
  CreatePatternGroupsRequest,
  DetailedCertificate,
  DetailedCertificatePatternGroup,
  DetailedCertificatePatternGroupLanguage,
  FormCertificate,
  FormCertificatePatternGroup,
  FormCertificatePatternGroupValue
} from '../models/certificate';
import { Unit } from '../models/unit';
import { mapCollection, push, reduce, length } from './collection-utils';
import { findLangByCode } from './language-utils';
import { keys } from './object-utils';

export function transformFromApi(record?: DetailedCertificate) {
  return {
    ...record,
    revisedAt: DateTime.fromISO(record.revisedAt),
    observation: tranformFromObservation(record?.languages ?? []),
    certificateTitle: tranformFromCertificateTitle(record?.languages ?? []),
    patternGroups: mapCollection(
      transformFromPatternGroup,
      record?.patternGroups
    ),
    checklists: transformFromChecklists(record?.checklists ?? []),
    machineChecklists: transformFromChecklists(record?.machineChecklists ?? [])
  };
}

const tranformFromObservation = (languages) =>
  reduce(
    (arr: any, elem: any) =>
      Object.assign(arr, { [elem.code]: elem.observation }),
    {},
    languages ?? []
  );

const tranformFromCertificateTitle = (languages) =>
  reduce(
    (arr: any, elem: any) =>
      Object.assign(arr, { [elem.code]: elem.certificateTitle }),
    {},
    languages ?? []
  );

const transformFromPatternGroup = (
  group: DetailedCertificatePatternGroup
): FormCertificatePatternGroup => {
  const clientObservation: FormCertificatePatternGroup['clientObservation'] =
    reduce(
      (
        acc: FormCertificatePatternGroup['clientObservation'],
        lang: DetailedCertificatePatternGroupLanguage
      ) => ({
        ...acc,
        [lang.code]: { id: lang.id, text: lang.clientObservation }
      }),
      {},
      group.languages
    );

  const formGroup: FormCertificatePatternGroup = {
    id: group.id,
    patternGroupId: group.patternGroup.id,
    magnitude: group.magnitude,
    unit: group.unit?.id ?? null,
    scale: group.scale,
    scaleDivision: group.scaleDivision,
    resolution: group.resolution,
    indicator: group.indicator,
    satNote: group.satNote,
    clientObservation,
    done: group.done,
    patternIds: mapCollection((pattern) => pattern.id, group.patterns),
    values: group.values,
    results: group.results,
    hasExtraField: group.hasExtraField,
    patternResultPrimary: group.patternResultPrimary
  };

  return formGroup;
};

export const transformToApi = (
  data: FormCertificate,
  clientMachineId?: string
) => {
  return {
    ...data,
    ...(clientMachineId ? { clientMachineHash: clientMachineId } : {}),
    humidity: Number(data.humidity?.toString().replace(',', '.')),
    temperature: Number(data.temperature?.toString().replace(',', '.')),
    patternGroups: mapCollection(transformToPatternGroup, data.patternGroups),
    languages: transformToLanguages(data.observation, data.certificateTitle),
    checklists: transformToChecklists(data.checklists)
  };
};

const transformFromChecklists = (checklists) => {
  return mapCollection(
    (checklist: any) => ({
      ...checklist,
      name: reduce(
        (arr: any, elem: any) => Object.assign(arr, { [elem.code]: elem.name }),
        {},
        checklist.languages
      ),
      elements: transformChecklistElements(
        checklist.elements,
        checklist.languages
      )
    }),
    checklists
  );
};

const transformChecklistElements = (elements, languages) => {
  const rows = elements.map((element) => ({ done: element.done }));

  for (const language of languages) {
    const languageElements = language.elements;
    for (let j = 0; j < length(languageElements); j++) {
      const element = languageElements[j];

      if (!rows[j]) {
        rows[j] = {};
      }
      if (!rows[j].translations) {
        rows[j].translations = {};
      }
      rows[j].translations[language.code] = element.name;
    }
  }

  return rows;
};

const transformToLanguages = (observations, certificateTitles) => {
  let languages = [];

  keys(observations).forEach((key) => {
    const code = key;
    const observation = observations[key];
    const certificateTitle = certificateTitles[key];

    const existingLanguage = findLangByCode(languages, code);

    if (existingLanguage) {
      existingLanguage.observation = observation;
      existingLanguage.certificateTitle = certificateTitle;
    } else {
      languages = push(languages, { code, observation, certificateTitle });
    }
  });

  return languages;
};

const transformToPatternGroup = (
  formGroup: FormCertificatePatternGroup & { unit: Unit }
): CreatePatternGroupsRequest => {
  const languages = mapCollection(
    (lang) => ({
      id: formGroup.clientObservation[lang].id,
      code: lang,
      clientObservation: formGroup.clientObservation[lang].text
    }),
    keys(formGroup.clientObservation)
  );

  const group = {
    id: formGroup.id,
    patternGroupId: formGroup.patternGroupId,
    patternGroup: {
      id: formGroup.patternGroupId
    },
    unit: formGroup.unit,
    magnitude: formGroup.magnitude.id,
    scale: formGroup.scale,
    scaleDivision: formGroup.scaleDivision,
    resolution: formGroup.resolution,
    indicator: formGroup.indicator,
    satNote: formGroup.satNote,
    languages,
    done: formGroup.done,
    patterns: mapCollection(
      (pattern) => ({ id: pattern }),
      formGroup.patternIds
    ),
    values: formGroup.values.map(transformToPatternGroupValue),
    hasExtraField: formGroup.hasExtraField,
    patternResultPrimary: formGroup.patternResultPrimary,
    results: formGroup.results
  };

  return group;
};

const transformToPatternGroupValue = (
  value: FormCertificatePatternGroupValue
) => ({
  ...value,
  measurement: Number(value.measurement?.toString().replace(',', '.')),
  datas: value.datas.map((data) => ({
    ...data,
    data: Number(data?.data?.toString().replace(',', '.'))
  }))
});

const transformToChecklists = (checklists) => {
  return mapCollection(
    (checklist: any) => ({
      ...checklist,
      languages: transformToChecklistLanguages(
        checklist.elements,
        checklist.name
      )
    }),
    checklists
  );
};

const transformToChecklistLanguages = (elements, names) => {
  let languages = [];

  elements.forEach((element) => {
    keys(element.translations).forEach((key) => {
      const code = key;
      const name = element.translations[key];

      const existingLanguage = findLangByCode(languages, code);

      if (existingLanguage) {
        existingLanguage.elements.push({ name });
      } else {
        languages = push(languages, { code, elements: [{ name }] });
      }
    });
  });

  keys(names).forEach((key) => {
    const code = key;
    const name = names[key];

    const existingLanguage = findLangByCode(languages, code);

    if (existingLanguage) {
      existingLanguage.name = name;
    } else {
      languages = push(languages, { code, name });
    }
  });

  return languages;
};
